import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import { StaticImage } from 'gatsby-plugin-image'

import Scrollspy from 'react-scrollspy'
import Scroll from '../../components/Scroll'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'
import Nav from '../../components/Nav'

import { Link } from 'gatsby'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import xd01 from '../../assets/images/xd/xd_sc1.jpg'
import xd02 from '../../assets/images/xd/xd_sc2.jpg'
import xd03 from '../../assets/images/xd/xd_sc3.jpg'
import xd04 from '../../assets/images/xd/xd_sc4.jpg'
import xd05 from '../../assets/images/xd/xd_sc5.jpg'

const CrossDimension = ({ data, location }) => {
  const [stickyNav, setStickyNav] = useState(false)
  const handleWaypointEnter = () => {
    setStickyNav(false)
  }
  const handleWaypointLeave = () => {
    setStickyNav(true)
  }

  const xd_main = '../../assets/images/xd/xd_main.png'
  const chara01 = '../../assets/images/xd/hero0.jpg'
  const chara02 = '../../assets/images/xd/hero1.jpg'
  const chara03 = '../../assets/images/xd/hero2.jpg'
  const chara04 = '../../assets/images/xd/hero3.jpg'
  const chara05 = '../../assets/images/xd/hero4.jpg'
  const chara06 = '../../assets/images/xd/hero5.jpg'

  return (
    <Layout>
      <Helmet title="CROSS DIMENSION" />
      <HeaderGeneric />
      <Waypoint
        onEnter={handleWaypointEnter}
        onLeave={handleWaypointLeave}
      ></Waypoint>
      <Nav sticky={stickyNav} />

      <div id="main">
        <section id="top" className="main special">
          <header className="major">
              <h2>CROSS DIMENSION</h2>
          </header>
          <span className="image major aligncenter"><StaticImage src={xd_main} alt="" width="950" /></span>
        </section>
        <section id="index" className="main">
          <Scrollspy items={ ['download', 'movie', 'content', 'sc', 'colum'] } currentClassName="is-active" offset={-300}></Scrollspy>
          <ul>
            <li>
              <Scroll type="id" element="download">
                  <a href="#download">ダウンロード</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="movie">
                <a href="#movie">ムービー</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="content">
                <a href="#content">内容</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="sc">
                <a href="#sc">スクリーンショット</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="colum">
                <a href="#colum">コラム</a>
              </Scroll>
            </li>
          </ul>
        </section>
        <section id="download" className="main">
          <header className="major">
            <h2>ダウンロード</h2>
          </header>
          <p>下記サイトよりダウンロードできます</p>
          <ul className="actions">
            <li>
              <a href="https://cretia-studio.booth.pm/items/936326" target="_blank" rel="noopener noreferrer" className="button special">BOOTH</a>
            </li>
            <li>
              <a href="https://www.freem.ne.jp/win/game/7562" target="_blank" rel="noopener noreferrer" className="button">ふりーむ！</a>
            </li>
            <li>
              <a href="https://www.vector.co.jp/soft/winnt/game/se507924.html" target="_blank" rel="noopener noreferrer" className="button">Vector</a>
            </li>
          </ul>
        </section>
        <section id="movie" className="main">
          <header className="major">
            <h2>ムービー</h2>
          </header>
          <div className="video-player">
            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/UaU9lJJk2PM?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
          </div>
        </section>
        <section id="content" className="main">
          <header className="major">
            <h2>内容</h2>
          </header>
          <h3>◆ ストーリー</h3>
          <p>西暦2050年。<br />
          過去の戦争によって多くの損害を被ったヴァルニアだったが、急速に復興を進めると同時に軍備に力を注いでいた。<br />
          そのヴァルニアの学術研究機関である鶴桜学院に通うキョウヤ、ヒカリ、カエデ。<br />
          しかし、彼らの教授である新崎イツキの失踪をきっかけに、物語は大きく動き出す……。</p>
          <p>近未来とファンタジー。<br />
          二つの世界の物語が今、クロスする！</p>

          <h3>◆ 2つの世界</h3>
          <div className="xd-bg1">
            <p>ミッドガル</p>
            <p>凄まじい技術の発展によって栄えた人間達の世界。<br />
            大国ヴァルニアとリジールの冷戦が続き、一触即発の危機に晒されている。</p>
          </div>
          <div className="xd-bg2">
            <p>シンズヘイム</p>
            <p>自然の力を周囲や自らに循環させ、意のままに操ることが出来るシンと呼ばれる者が住む世界。<br />
            彼らシンはミッドガルの世界を認識しており、過去の因縁から人間達に対し、強く恨みを持っている。</p>
          </div>

          <h3>◆ キャラクター</h3>
          <div className="xd-chara">
            <div><StaticImage src={chara01} alt="" /></div>
            <p>神河 キョウヤ</p>
            <p>鶴桜学院 軍事高等部3年。18歳。<br />
            明るい性格でクラスメイトの評判も良く、場を盛り上げるムードメーカー的存在。<br />
            高等部2年でカエデと親友になり、3年で転入してきたヒカリと3人でよく行動するようになった。<br />
            学業、演習科目ともに学年トップ５に入るほどの実力派であり、秀才型である。<br />
            武器は広く一般的に普及しているハンドガンを用い、素早い動きを得意とする。</p>
          </div>
          <div className="xd-chara">
            <div><StaticImage src={chara02} alt="" /></div>
            <p>ヒカリ・アネスティ</p>
            <p>鶴桜学院 軍事高等部3年。18歳。<br />
            3年の春に転入し、キョウヤとカエデと行動をよく共にするようになる。<br />
            活発で勝ち気な性格だが容姿端麗であり、すぐに学院内のアイドルと称されるようになる。<br />
            戦闘訓練では、女の子の力では到底持ち上げられない大剣を鮮やかに振るう。</p>
          </div>
          <div className="xd-chara">
            <div><StaticImage src={chara03} alt="" /></div>
            <p>新崎 カエデ</p>
            <p>鶴桜学院 軍事高等部3年。18歳。<br />
            真面目な性格で、キョウヤとヒカリの世話役。<br />
            頭の回転も速く、コンピュータに関する能力はずば抜けて高い。<br />
            周りからは天才と謳われてはいるが、実際にカエデに近づこうとする者はいなかった。<br />
            しかし、唯一普通に接してくれたキョウヤに、次第にうち解けていった。<br />
            戦闘訓練では狙撃を得意とする。</p>
          </div>
          <div className="xd-chara">
            <div><StaticImage src={chara04} alt="" /></div>
            <p>リリィ・シュドレイ</p>
            <p>ウェスナム学院高等魔法部1年。16歳。<br />
            魔法部の首席であり、他に類を見ないサイコキネシスの能力者。<br />
            とある理由で学内では忌み嫌われていたが、キョウヤ達との出会いによって心を開いていく。<br />
            母親からもらったぬいぐるみを常に持ち歩いており、サイコキネシスの能力で生きているかのように操っている。</p>
          </div>
          <div className="xd-chara">
            <div><StaticImage src={chara05} alt="" /></div>
            <p>マリア・クローゼ</p>
            <p>リジールにある静寂の村クレドの修道女。19歳。<br />
            美しく穏やかな性格で、クレドの村に住む人々の心の支えとなっている。<br />
            過去にキョウヤと面識があり、その両親に助けられたことがある。<br />
            ミッドガルの人間には使えないはずの治癒魔法を使うことができ、再会したキョウヤらと共に自らの運命に立ち向かう。</p>
          </div>
          <div className="xd-chara">
            <div><StaticImage src={chara06} alt="" /></div>
            <p>シエルド・ガルム</p>
            <p>ガルムの名を持つ誇り高きネル族の一人。<br />
            ネル族を治める次期長老とされている。<br />
            マリアがとある人物と関連性が強いことから、彼女の護衛として旅を共にするようになる。<br />
            普段は争いを好まない種族だが、生まれ持った高い魔法の力と鍛え抜かれた槍術は、亜人族に引けをとらない。</p>
          </div>

          <div className="clear-both"></div>
          <hr />

          <table>
            <colgroup><col span="1" /></colgroup>
            <tbody>
              <tr><th>タイトル</th><td>CROSS DIMENSION -クロス ディメンション-</td></tr>
              <tr><th>プラットフォーム</th><td>Windows 10 / 8 / 7 / Vista</td></tr>
              <tr><th>ジャンル</th><td>RPG</td></tr>
              <tr><th>提供形態</th><td>フリーウェア</td></tr>
              <tr><th>リリース日</th><td>2014年 8月 3日</td></tr>
            </tbody>
          </table>
        </section>
        <section id="sc" className="main">
          <header className="major">
            <h2>スクリーンショット</h2>
          </header>
          <Carousel className="carousel-custom horizon-sc" width={340} showArrows={true} showThumbs={true} showIndicators={false}>
              <div>
                  <img src={xd01} alt="" />
              </div>
              <div>
                  <img src={xd02} alt="" />
              </div>
              <div>
                  <img src={xd03} alt="" />
              </div>
              <div>
                  <img src={xd04} alt="" />
              </div>
              <div>
                  <img src={xd05} alt="" />
              </div>
          </Carousel>
        </section>
        <section id="colum" className="main">
          <header className="major">
            <h2>コラム</h2>
          </header>
          <ul>
            <li><Link to="/blog/20150103-first">8年かけて作った自作RPGの話</Link></li>
          </ul>
        </section>
      </div>
    </Layout>
  )
}

export default CrossDimension
